export default {
  props: {
    label: String,
    date: String,
    rules: Array,
    disabled: Boolean,
    required: Boolean,
    displayFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    outputFormat: {
      type: String,
      default: ''
    },
    inputName: String,
    dense: { type: Boolean },
    hideDetails: { type: Boolean }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    value: {
      get() {
        return this.date ? this.$moment(this.date).local().format(this.displayFormat) : null
      },
      set(v) {
        const localNoon = v ? this.$moment(v).hour(12).minute(0).second(0).utc().format(this.outputFormat) : null
        this.$emit('update:date', localNoon)
        this.menu = false
      }
    }
  }
}
