import WorkflowTicketFilter from '@/components/Workflow/WorkflowTicketFilter/WorkflowTicketFilter.vue'
import WorkflowTicketsDataTable from '@/components/Workflow/WorkflowTicketsDataTable/WorkflowTicketsDataTable.vue'
import CustomizeColumn from '@/components/Workflow/CustomizeColumn/CustomizeColumn.vue'
import ExportWorkflowMixin from '@/mixins/export.workflow.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  props: ['filterId', 'filterProp', 'searchProp'],
  components: { WorkflowTicketFilter, WorkflowTicketsDataTable, CustomizeColumn },
  mixins: [ExportWorkflowMixin, QueryMixin],
  data() {
    return {
      workflowTickets: [],
      workflowTicketsCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['updatedAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: '',
      debouncing: false,
      debounceTimeout: null,
      filter: {},
      workflowTicketFilter: null,
      workflowTicketFilterLoaded: false,
      workflowDialogOpen: false
    }
  },
  computed: {
    title() {
      return _.startCase(this.currentType.name)
    }
  },
  watch: {
    options: {
      handler() {
        this.reload()
      },
      deep: true
    },
    search() {
      this.reload()
    },
    filter: {
      handler() {
        this.reload()
      },
      deep: true
    },
    filterProp: {
      handler() {
        if (this.filterProp) {
          this.filter = this.filterProp
        }
      }
    },
    searchProp: {
      handler() {
        if (this.searchProp) {
          this.search = this.searchProp
        }
      }
    }
  },
  methods: {
    openWorkflowDialog(ticket) {
      if (ticket) {
        this.workflowDialogOpen = true
      } else {
        this.workflowDialogOpen = false
      }
    },
    tagClicked(tag) {
      this.search = tag
    },
    onFilterUpdate(filter) {
      if (!_.isEqual(this.filter, filter)) {
        this.filter = filter
      }
    },
    getWorkflowTicketsVariables() {

      let whereExpression = null

      if (this.filter.filterType === 'WorkflowTicket') {
        whereExpression = this.filterToWhereExpression(this.filter)
      }

      return {
        take:
          this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
        skip:
          this.options.itemsPerPage === -1
            ? 0
            : this.options.itemsPerPage * (this.options.page - 1),
        orderBy: this.getOrderBy(),
        where: whereExpression,
        q: this.search
      }
    },
    reload() {
      this.$apollo.queries.workflowTickets.refetch(this.getWorkflowTicketsVariables())
    }
  },
  apollo: {
    workflowTickets: {
      query() {
        const allFields = [
          'id',
          'externalId',
          'name',
          'applicant',
          'creatorEmployeeId',
          'costCode',
          'location',
          'memo',
          'updatedAt',
          'requestedDeliveryDate',
          'status'
        ]
        .concat(this.extraFields.filter(f => !f.fields).map(f => f.name))
        .concat(this.extraFields.filter((f) => f.fields))
        
        const fields = this.getFieldsGql(
          'read',
          this.currentType.graphTypeName,
          allFields
        )

        return this.$gql`
          query workflowTickets($take: Int, $skip: Int, $orderBy: [OrderBy], $where: [WhereExpression], $q: String) {
            ${this.currentType.listQueryName}(take: $take, skip: $skip, orderBy: $orderBy, where: $where, search: $q) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return this.getWorkflowTicketsVariables()
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          _.isEmpty(this.currentType) ||
          !this.ability.can('read', this.currentType.graphTypeName)
        )
      },
      update: () => [],
      result({ data, error }) {
        if (data && data[this.currentType.listQueryName]) {
          if (this.updatedItem !== undefined) {
            for (let i = 0; i < data[this.currentType.listQueryName].items.length; i++) {
              if (data[this.currentType.listQueryName].items[i].id === this.updatedItem.id) {
                data[this.currentType.listQueryName].items[i] = this.updatedItem
                break 
              }
            }
          }
          this.workflowTickets = data[this.currentType.listQueryName].items
          this.workflowTicketsCount = data[this.currentType.listQueryName].totalCount

          if (
            Math.ceil(this.workflowTicketsCount / this.options.itemsPerPage) <
            this.options.page
          ) {
            this.options.page = 1
          }
        } else if (error) {
          this.graphQLOnError(`Failed to get workflow tickets. ${error.toString()}`)
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.workflowDialogOpen) {
      this.$refs.workflowTicketsDatatable.closeWorkflowDialog()
      return next(this.$route)
    } else {
      return next()
    }
  },
  created() {
    if (this.filter && this.filter.filterType !== 'Workflow') {
      this.filter = {}
    }
    if (this.filterProp) {
      this.filter = this.filterProp
    }
    if (this.searchProp) {
      this.search = this.searchProp
    }
  }
}
