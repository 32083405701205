var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"workflow-tickets-data-table elevation-1 mt-4",attrs:{"headers":_vm.displayedHeaders,"items":_vm.items,"loading":_vm.loading,"footer-props":_vm.footerProps,"options":_vm.localOptions,"server-items-length":_vm.workflowTicketsCount,"multi-sort":""},on:{"update:options":function($event){_vm.localOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',[_c('workflow-ticket-filter',{attrs:{"filter":JSON.parse(JSON.stringify(_vm.filter))},on:{"update":_vm.onFilterUpdate}}),_c('v-spacer'),_c('v-flex',{staticClass:"justify-end align-end",attrs:{"d-flex":"","align-self-end":""}},[(!_vm.loading && _vm.workflowTicketsCount > 0)?_c('div',{staticClass:"caption py-2 mx-4"},[_vm._v(" "+_vm._s(_vm.itemFrom)+"-"+_vm._s(_vm.itemTo)+" of "+_vm._s(_vm.workflowTicketsCount)+" ")]):_c('div',{staticClass:"caption py-2 mx-4"},[_vm._v("–")]),_c('div',{staticClass:"px-2"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page <= 1},on:{"click":_vm.previousPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('div',{staticClass:"px-2"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page >= _vm.totalPage},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])],1)],1),_c('v-divider')]},proxy:true},(_vm.isTypeSpecific)?{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){$event.stopPropagation();return _vm.openWorkflowDialog(item)}}}),_c('v-btn',{attrs:{"icon":"","x-small":"","href":("/tickets/" + (_vm.currentType.name) + "/" + (item.id) + "/edit"),"target":"_blank","color":"primary"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}:{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){$event.stopPropagation();return _vm.openWorkflowDialog(item)}}}),_c('v-btn',{attrs:{"icon":"","x-small":"","href":("/tickets/" + (_vm.getType(item.discriminator)) + "/" + (item.id) + "/edit"),"target":"_blank","color":"primary"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.operatorComputer",fn:function(ref){
var item = ref.item;
return [(!_vm._.isEmpty(item.operatorComputer))?[_vm._v(" "+_vm._s(_vm._.get(item, 'operatorComputer.name'))+" "),(!_vm._.isEmpty(item.operatorComputer.serialNumber))?[_vm._v(" ("+_vm._s(_vm._.get(item, 'operatorComputer.serialNumber'))+") ")]:_vm._e()]:_vm._e()]}},{key:"item.applicant",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proxyApplication !== undefined && item.proxyApplication ? item.applyForUsername : item.applicant)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.updatedAt))+" ")]}},{key:"item.applicationType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.applicationType ? 'Return Pc Accessory' : 'New Pc Accessory')+" ")]}},{key:"item.requestedDeliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.requestedDeliveryDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statuses.filter(function (s) { return s.value === item.status; })[0] == null ? item.status : _vm.statuses.filter(function (s) { return s.value === item.status; })[0].text)+" ")]}},{key:"item.memo",fn:function(ref){
var item = ref.item;
return [_c('memo',{attrs:{"text":item.memo,"readonly":true},on:{"update:text":function($event){return _vm.$set(item, "memo", $event)},"tagClicked":_vm.tagClicked}})]}}],null,true)}),_c('pagination',{attrs:{"options":_vm.options,"totalPage":_vm.totalPage}}),_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","no-click-animation":""},model:{value:(_vm.isWorkflowDialogOpen),callback:function ($$v) {_vm.isWorkflowDialogOpen=$$v},expression:"isWorkflowDialogOpen"}},[(_vm.dialogTicket)?_c('v-card',[_c('v-toolbar',{attrs:{"left":"","flat":"","dense":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm._.startCase(_vm.dialogTicket.__typename)))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeWorkflowDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.dialogTicket !== null && _vm.dialogTicket.id !== null)?_c('workflow-details',{attrs:{"id":_vm.dialogTicket.id,"workflowTicketGraphType":_vm.dialogTicket.__typename}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }