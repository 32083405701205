import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import Pagination from '@/components/Pagination/Pagination.vue'
import GraphqlMixin from '@/mixins/graphql.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
import WorkflowDetails from '@/components/Workflow/WorkflowDetails/WorkflowDetails.vue'
import Memo from '@/components/inputs/Memo/Memo.vue'
import WorkflowTicketFilter from '@/components/Workflow/WorkflowTicketFilter/WorkflowTicketFilter.vue'

export default {
  props: [
    'workflowTickets',
    'workflowTicketsCount',
    'loading',
    'options',
    'filter'
  ],
  components: { WorkflowTicketFilter, Pagination, WorkflowDetails, Memo },
  mixins: [WorkflowTicketMixin, GraphqlMixin, FormattingMixin],
  data() {
    return {
      isWorkflowDialogOpen: false,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      localOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['updatedAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      dialogTicket: null
    }
  },
  watch: {
    options: {
      handler(v) {
        this.localOptions = v
      },
      deep: true
    },
    localOptions: {
      handler(v) {
        this.$emit('update:options', v)
      },
      deep: true
    }
  },
  computed: {
    items() {
      if (this.loading) {
        return []
      } else {
        return this.workflowTickets
      }
    },
    itemFrom() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1
    },
    itemTo() {
      return Math.min(
        this.options.page * this.options.itemsPerPage,
        this.workflowTicketsCount
      )
    },
    totalPage() {
      return this.options.itemsPerPage === -1 || this.workflowTicketsCount === 0
        ? 1
        : Math.ceil(this.workflowTicketsCount / this.options.itemsPerPage)
    },
    displayedHeaders() {
      const graph = _.get(this, 'currentType.graphTypeName', 'WorkflowTicket')
      return this.$store.getters['settings/getWorkflowTicketDisplayedHeaders'](
        graph
      )
    },
    isTypeSpecific() {
      return !(this.currentType.name === 'allPcTickets' || this.currentType.name === 'allMobileTickets')
    }
  },
  methods: {
    openWorkflowDialog(ticket) {
      this.dialogTicket = ticket
      this.isWorkflowDialogOpen = true
      this.$emit('openWorkflowDialog', ticket)
    },
    closeWorkflowDialog() {
      this.dialogTicket = null
      this.isWorkflowDialogOpen = false
      this.$emit('openWorkflowDialog', null)
    },
    tagClicked(tag) {
      this.$emit('tagClicked', tag)
      this.isWorkflowDialogOpen = false
    },
    previousPage() {
      if (!this.loading && this.options.page > 1) {
        this.options.page--
      }
    },
    nextPage() {
      if (!this.loading && this.options.page < this.totalPage) {
        this.options.page++
      }
    },
    onFilterUpdate(f) {
      this.$emit('update', f)
    },
    getType(discriminator) {
      switch (discriminator) {
        case 'DesktopDeployment':
          return 'desktopDeployments'
        case 'LaptopDeployment':
          return 'laptopDeployments'
        case 'PcAccessory':
          return 'pcAccessories'
        case 'PcUserChange':
          return 'pcUserChanges'
        case 'PcOsRefresh':
          return 'pcOsRefreshes'
        case 'PcRepair':
          return 'pcRepairs'
        case 'PcReturn':
          return 'pcReturns'
        case 'MobileDeployment':
          return 'mobileDeployments'
        case 'MobileReturn':
          return 'mobileReturns'
        case 'MobileSimChange':
          return 'mobileSimChanges'
        case 'MobileUserChange':
          return 'mobileUserChanges'
        default:
          return discriminator
      }
    }
  },
  mounted() {
    // Close modal with 'esc' key
    window.removeEventListener('keydown', null)
    window.addEventListener(
      'keydown',
      (event) => {
        if (event.code === 'Escape') {
          this.closeWorkflowDialog()
        }
      },
      true
    )
  },
  beforeDestroy() {
    window.removeEventListener('keydown', null)
  }
}
