import GraphQlMixin from '@/mixins/graphql.mixin'
import { mapGetters } from 'vuex'

export default {
  props: ['headerType'],
  mixins: [GraphQlMixin],
  data() {
    return {
      selectedHeaders: []
    }
  },
  computed: {
    currentAvailableHeaders() {
      return this.headerType ? this.availableHeaders[this.headerType] : []
    },
    availableHeaders() {
      const headers = {}
      if (this.$store.getters['graphql/workflowTicketType'].graphTypeName === 'PcWorkflowTicket') {
        this.pcWorkflowTicketTypes.forEach(wt => {
          headers[wt.graphTypeName] = []
          _.forEach(this.recomputeIndex(wt.headers), f => {
            let canRead = false
            let subject = this.headerType
            _.forEach(_.split(f.value, '.'), (fieldName, index) => {
              if (subject === 'PcUserChangeOnly') {
                subject = 'PcUserChange'
              }
              var fieldNameProperCase = fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
              canRead = this.ability.can('read', _.startCase(subject).replace(/\s+/g, ''), fieldName) || this.ability.can('read', _.startCase(subject).replace(/\s+/g, ''), fieldNameProperCase)
              subject = fieldName
            })
            if (canRead) {
              headers[wt.graphTypeName].push(f)
            }
          })
        })
      } else {
        this.mobileWorkflowTicketTypes.forEach(wt => {
          headers[wt.graphTypeName] = []
          _.forEach(this.recomputeIndex(wt.headers), f => {
            let canRead = false
            let subject = this.headerType
            _.forEach(_.split(f.value, '.'), (fieldName, index) => {
              if (fieldName === 'operatorMobile' || fieldName === 'OperatorMobile') {
                fieldName = 'mobile'
              }
              var fieldNameProperCase = fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
              canRead = this.ability.can('read', _.startCase(subject).replace(/\s+/g, ''), fieldName) || this.ability.can('read', _.startCase(subject).replace(/\s+/g, ''), fieldNameProperCase)
              subject = fieldName
            })
            if (canRead) {
              headers[wt.graphTypeName].push(f)
            }
          })
        })
      }
      return headers
    },
    ...mapGetters('graphql', ['pcWorkflowTicketTypes']),
    ...mapGetters('graphql', ['mobileWorkflowTicketTypes'])
  },
  watch: {
    headerType() {
      this.loadHeaders()
    },
    selectedHeaders: {
      handler() {
        let savedHeaders = this.$store.state.settings.workflowTicketHeaders
        savedHeaders[this.headerType] = this.selectedHeaders
        this.$store.commit('settings/setWorkflowTicketHeaders', savedHeaders)
      },
      deep: true
    }
  },
  methods: {
    recomputeIndex(headers) {
      return _.map(headers, (x, i) => ({
        ...x,
        index: i
      }))
    },
    loadHeaders() {
      const diffFunc = (x, y) => x.index === y.index && x.text === y.text && x.value === y.value
      if (this.headerType) {
        let savedHeaders = this.$store.state.settings.workflowTicketHeaders
        let headers = _.get(savedHeaders, this.headerType)

        if (!_.isEmpty(headers)) {
          // remove saved columns that are no longer available
          _.pullAllBy(headers, _.differenceWith(headers, this.currentAvailableHeaders, diffFunc))
          // append new columns, make sure the headers are unique
          this.selectedHeaders = _.uniqBy(_.concat(headers, _.differenceWith(this.currentAvailableHeaders, headers, diffFunc).map(h => ({...h, show: true}), 'text')))
        } else {
          this.selectedHeaders = this.currentAvailableHeaders.map(h => ({...h, show: true}))
        }
        savedHeaders[this.headerType] = this.selectedHeaders
        this.$store.commit('settings/setWorkflowTicketHeaders', savedHeaders)
      }
    }
  },
  created() {
    this.loadHeaders()
  }
}
