import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import ApiService from '@/services/api.service'
import { saveAs } from 'file-saver'

export default {
  mixins: [WorkflowTicketMixin],
  data() {
    return {
      downloading: false
    }
  },
  methods: {
    downloadWorkflowTickets(format) {
      const Where = this.filterToWhereExpression(this.filter)
      if (this.currentType.name === 'pcUserChangesOnly') {
        this.download(format, 'PcUserChangeOnly', Where)
      } else {
        if (this.$route.params.type === 'allPcTickets') {
          this.download(format, 'PcWorkflowTicket', Where)
        } else if (this.$route.params.type === 'allMobileTickets') {
          this.download(format, 'MobileWorkflowTicket', Where)
        } else {
          this.download(format, this.currentType.graphTypeName, Where)
        }
      }
    },
    download(format, dataType, Where) {

      if (Where !== null && Where.length > 0 && (dataType === 'PcUserChange' || dataType === 'PcUserChangeOnly')) {
          Where[0].groupedExpressions = Where[0].groupedExpressions.filter(x => x.path !== 'osReinstallation')
      }

      this.downloading = true

      ApiService.customRequest({
        method: 'post',
        url: '/export',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob',
        data: {
          dataType,
          format,
          Search: this.search,
          Where
        }
      })
        .then(response => {
          saveAs(
            new Blob([response.data]),
            `${dataType} ${this.formatDisplayDateTime(new Date())}.${format === 'Excel' ? 'xlsx' : 'csv'}`
          )
        })
        .catch(error => {
          this.addError(null, `Download failed. ${error}`)
        })
        .finally(() => {
          this.downloading = false
        })
    }
  }
}
