import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import CreateDialog from '@/components/Workflow/WorkflowTicketFilter/CreateDialog/CreateDialog.vue'
import FilterTreeview from '@/components/Workflow/WorkflowTicketFilter/FilterTreeview/FilterTreeview.vue'

export default {
  props: ['filter'],
  mixins: [WorkflowTicketMixin],
  components: {
    CreateDialog,
    FilterTreeview
  },
  data() {
    return {
      localFilter: {},
      showCreateDialog: false,
      showSaveDialog: false,
      showLoadDialog: false,
      editWorkflowTicketFilterItem: null
    }
  },
  computed: {
    hasFilter() {
      return _.get(this.localFilter, 'filters.length', 0) > 0
    }
  },
  watch: {
    filter: {
      handler() {
        this.localFilter = this.filter
      },
      deep: true
    }
  },
  methods: {
    apply(filter) {
      this.localFilter = filter
      this.$emit('update', this.localFilter)
    }
  },
  mounted() {
    this.localFilter = this.filter
  }
}