import FilterGroup from './FilterGroup/FilterGroup.vue'
import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'

export default {
  props: ['show', 'filter'],
  components: { FilterGroup },
  mixins: [WorkflowTicketMixin],
  data: () => ({
    id: null,
    dialog: false,
    rootFilter: {
      negate: false,
      connector: 'and',
      filters: [],
      group: true,
      root: true,
      errors: [],
      filterType: 'WorkflowTicket'
    }
  }),
  watch: {
    show(v) {
      this.updateFilter()
      this.dialog = v
    },
    dialog(v) {
      this.$emit('update:show', v)
    },
    filter: {
      handler() {
        this.updateFilter()
      },
      deep: true
    }
  },
  methods: {
    updateFilter() {
      if (_.isEmpty(this.filter)) {
        this.rootFilter = {
          negate: false,
          connector: 'and',
          filters: [],
          group: true,
          root: true,
          errors: [],
          filterType: 'WorkflowTicket'
        }
      } else {
        this.rootFilter = this.filter
      }
    },
    validate() {
      if (this.validateGroup(this.rootFilter)) {
        this.apply()
      }
    },
    validateFilter(filter) {
      filter.errors = []
      if (
        _.isEmpty(filter) ||
        _.isEmpty(filter.field) ||
        _.isEmpty(filter.operator)
      ) {
        filter.errors.push('Field / operator cannot be empty.')
      }
      if (
        (!_.isEmpty(filter.operator) && (filter.operator.value === 'in' ||
          filter.operator.value === 'notIn')) &&
        _.isEmpty(filter.value)
      ) {
        filter.errors.push(
          'Value cannot be empty when using in / not in operator.'
        )
      }
      return filter.errors.length === 0
    },
    validateGroup(group) {
      let valid = true
      group.errors = []

      // root group can be empty, else not
      if (!group.root && _.isEmpty(group.filters)) {
        group.errors.push('Filter group cannot be empty.')
      }
      for (let filter of group.filters) {
        if (filter.group) {
          if (!this.validateGroup(filter)) {
            valid = false
          }
        } else {
          if (!this.validateFilter(filter)) {
            valid = false
          }
        }
      }
      return valid && group.errors.length === 0
    },
    apply() {
      this.$emit('apply', this.rootFilter)
      this.dialog = false
    }
  }
}
