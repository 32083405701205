import currencyToSymbolMap from 'currency-symbol-map/map'
import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import Date from '@/components/inputs/Date/Date.vue'

export default {
  props: ['filter'],
  mixins: [WorkflowTicketMixin],
  components: {
    Date
  },
  data: () => ({
    showPaths: false,
    search: null
  }),
  computed: {
    fields() {
      let fields = []

      if (this.currentType.name === 'allPcTickets' || this.currentType.name === 'allMobileTickets') {
        fields.push({
          text: 'Type',
          value: { name: 'discriminator', type: 'String', readOnly: true, extra: true, filterable: true }
        })
      }

      if (this.currentType && this.currentType.fields) {
        // remove list type from fields
        fields = fields.concat(
          this.currentType.fields
            .filter(f => f.type.indexOf('[') === -1)
            .filter(f =>
              this.ability.can('read', this.currentType.graphTypeName, f.name) || this.ability.can('read', this.currentType.graphTypeName, f.name.charAt(0).toUpperCase() + f.name.slice(1))
            )
            .filter(f => f.filterable)
            .map(f => ({ text: this.getFieldLabel(f.name), value: f }))
        )

        fields = fields.filter(f => f.text !== 'Discriminator')

        fields = fields.filter(f => f.text !== 'Name')
        fields.push({
          text: 'Document Number',
          value: { name: 'name', type: 'String' }
        })
      }

      return _.orderBy(fields, f => f.text, 'asc')
    },
    operators() {
      if (!this.filter || !this.filter.field) {
        return [{ text: '=', value: 'equal' }]
      }
      const options = [
        { text: '=', value: 'equal' },
        { text: '>', value: 'greaterThan' },
        { text: '>=', value: 'greaterThanOrEqual' },
        { text: '<', value: 'lessThan' },
        { text: '<=', value: 'lessThanOrEqual' },
        { text: 'contains', value: 'contains' },
        { text: 'starts with', value: 'startsWith' },
        { text: 'ends with', value: 'endsWith' },
        { text: 'in', value: 'in' },
        { text: 'like', value: 'like' }
      ]

      switch (this.currentFieldType) {
        case 'Boolean':
          return options.filter(o => ['equal'].includes(o.value))
        case 'String':
          return options.filter(
            o =>
              ![
                'greaterThan',
                'greaterThanOrEqual',
                'lessThan',
                'lessThanOrEqual'
              ].includes(o.value)
          )
        case 'Int':
        case 'Decimal':
        case 'Date':
        case 'DateTimeOffset':
          return options.filter(
            o =>
              !['contains', 'startsWith', 'endsWith', 'in', 'like'].includes(
                o.value
              )
          )
        case 'Status':
          return options.filter(o => ['equal', 'in'].includes(o.value))
        default:
          return options
      }
    },
    currentFieldType() {
      return this.getFilterFieldType(this.filter)
    },
    isMultiple() {
      return this.filter.operator && this.filter.operator.value === 'in'
    },
    filterStatuses() {
      return this.statuses.filter(x => x.workflowTicketTypes.includes(this.currentType.name)).map(x => x.text)
    },
    items() {
      if (_.get(this.filter, 'field.value.name') === 'pcCondition') {
        return ['New(Less than 1 year from the initial deployment date)', 'Second Hand(Used)']
      }
      return []
    }
  },
  watch: {
    'filter.operator'(op) {
      if (op) {
        if (
          op.value === 'in' &&
          this.filter.value &&
          !Array.isArray(this.filter.value)
        ) {
          this.filter.value = [this.filter.value]
        } else if (
          op.value !== 'in' &&
          Array.isArray(this.filter.value) &&
          this.filter.value.length > 0
        ) {
          this.filter.value = this.filter.value[0]
        }
      }
    }
  },
  methods: {
    filterFieldChanged() {
      const op = _.get(this.filter, 'field.operator')
      this.filter.value = op === 'in' ? [] : null
    },
    emitRemove() {
      this.$emit('remove')
    },
    modelFilter(item, queryText, itemText) {
      const queryLower = queryText.toLowerCase()
      return (
        _.toLower(item.name).indexOf(queryLower) > -1 ||
        _.toLower(item.modelNumber).indexOf(queryLower) > -1
      )
    }
  },
  apollo: {
    manufacturers: {
      query() {
        const fields = this.getFieldsGql('read', 'Manufacturer', ['id', 'name'])
        return this.$gql`
          query manufacturers($where: [WhereExpression])
          {
            manufacturers(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Manufacturer')
      },
      update({ manufacturers }) {
        return manufacturers.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(
            `Failed to get manufacturers. ${error.toString()}`
          )
        }
      }
    },
    mobiles: {
      query() {
        const fields = this.getFieldsGql('read', 'Mobile', ['id', 'name'])
        return this.$gql`
          query mobiles($where: [WhereExpression])
          {
            mobiles(where: $where, orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      },
      skip() {
        return !this.ability.can('read', 'Mobile')
      },
      update({ mobiles }) {
        return mobiles.items
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get mobiles. ${error.toString()}`)
        }
      }
    }
  }
}
