import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: ['filter'],
  mixins: [WorkflowTicketMixin],
  data: () => ({
    treeviewOpened: []
  }),
  computed: {
    treeviewItems() {
      return [this.filterGroupToTreeview(this.filter)]
    },
    hasFilter() {
      return _.get(this.filter, 'filters.length', 0) > 0
    }
  },
  watch: {
    treeviewItems: {
      handler() {
        if (this.treeviewItems.length > 0) {
          this.treeviewOpened = [this.treeviewItems[0].id]
        }
      },
      deep: true
    }
  },
  methods: {
    filterToTreeview(filter) {
      return {
        id: uuidv4(),
        name: `${_.get(filter, 'field.text')} ${filter.negate ? 'NOT ' : ''}${_.get(filter, 'operator.text')} ${_.truncate(this.getFilterDisplayValue(filter), { length: 120 })}`
      }
    },
    filterGroupToTreeview(group) {
      return {
        id: uuidv4(),
        name: `${group.negate ? 'Exclude' : 'Include'} results matching ${group.connector === 'and' ? 'ALL' : 'ANY'} of the following conditions/groups`,
        children: _.map(group.filters, f => f.group ? this.filterGroupToTreeview(f) : this.filterToTreeview(f))
      }
    },
    getFilterDisplayValue(f) {
      let value
      let type = this.getFilterFieldType(f)
      if (Array.isArray(f.value)) {
        switch (type) {
          case 'Model':
            value = f.value.map(v => `${v.name}, ${v.modelNumber}`)
            break
          default:
            value = f.value.map(v => (v.name ? v.name : v)).join(', ')
            break
        }
      } else {
        switch (type) {
          case 'Model':
            value = `${f.value.name}, ${f.value.modelNumber}`
            break
          default:
            value = _.get(f, 'value.name', f.value)
            break
        }
      }

      return value
    }
  }
}
